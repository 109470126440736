'use strict'

###*
 # @ngdoc object
 # @name mundoUtils.controller:MundoUtilsCtrl

 # @description

###
class MundoUtilsCtrl
  ### @ngInject ###
  constructor: ->
    @ctrlName = 'MundoUtilsCtrl'

angular
  .module('mundoUtils')
  .controller 'MundoUtilsCtrl', MundoUtilsCtrl
